@font-face {
  font-family: 'gilroy-medium';
  src: url('../assets/fonts/gilroy-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'gilroy-regular';
  src: url('../assets/fonts/gilroy-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'gilroy-semi-bold';
  src: url('../assets/fonts/gilroy-semi-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'mangoGrotesque-black';
  src: url('../assets/fonts/MangoGrotesque-Black.woff2') format('truetype');
}

