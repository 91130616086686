.loader {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #fff;
  box-shadow: -14px 0 #fff, 14px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 0.5s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #fff;
    box-shadow: -14px 0 #fcc300, 14px 0 #fff;
  }
  66% {
    background: #fcc300;
    box-shadow: -14px 0 #fff, 14px 0 #fff;
  }
  100% {
    background: #fff;
    box-shadow: -14px 0 #fff, 14px 0 #fcc300;
  }
}
