/* Add this to your CSS file or <style> tag */
.grid-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  
  .grid-item:hover img,
  .grid-item:hover video {
    opacity: 1;
    filter: brightness(1);
  }
  
  .download-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    display: none;
  }
  
  .grid-item:hover .download-button {
    display: block;
  }
  