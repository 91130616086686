@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

input.checked::before {
  content: "";
  -webkit-mask-image: url("../assets/svg/Ticks.svg");
  mask-image: url("../assets/svg/Ticks.svg");
  mask-size: 60%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  mask-repeat: no-repeat;
  position: absolute;
  top: 0;
  mask-position: center;
}

input.minus::before {
  content: "";
  -webkit-mask-image: url("../assets/svg/Minus.svg");
  mask-image: url("../assets/svg/Minus.svg");
  mask-size: 60%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  mask-repeat: no-repeat;
  position: absolute;
  top: 0;
  mask-position: center;
}

/* styles.css */
.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 8px; /* Set the height of the scrollbar */
  cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #dab07a;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #d4a15e;
}

/* styles.css */
.table-custom-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 8px; /* Set the height of the scrollbar */
  cursor: pointer;
}

.table-custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-custom-scrollbar::-webkit-scrollbar-thumb {
  background: #dab07a;
}

.table-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #d4a15e;
}

.tooltip-arrow {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.slider-circle {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background: #ccc;
  outline: none;
}

.slider-circle::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: radial-gradient(farthest-corner at 50% 50%, #fff 40%, #000 45%);
  cursor: pointer;
  margin-top: -5px;
}

.slider-circle::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: radial-gradient(farthest-corner at 50% 50%, #fff 40%, #000 45%);
  cursor: pointer;
  border: none; /* Add this line */
}

.slider-circle::-ms-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: radial-gradient(farthest-corner at 50% 50%, #fff 40%, #000 45%);
  cursor: pointer;
  margin-top: 0; /* Add this line */
}

.slider-circle::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    #000 var(--thumb-position),
    #ccc var(--thumb-position)
  );
}

.slider-circle::-moz-range-track {
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    #000 var(--thumb-position),
    #ccc var(--thumb-position)
  );
}

.slider-circle::-ms-track {
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    #000 var(--thumb-position),
    #ccc var(--thumb-position)
  );
}

.slider-circle::-moz-range-progress {
  background: #000;
}

.slider-circle::-ms-fill-upper {
  background: #ccc;
}
