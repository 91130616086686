/* CustomDatePicker.css */

/* Apply basic styles */
@media only screen and (min-width: 1280px) {
  .outside-datepiker-container {
    width: 789px;
    position: absolute;
    top: 40px;
    right: 0;
    padding: 30px;
    min-height: 387px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
    background-color: white;
    box-shadow: 0px 18px 23px -6px rgba(0, 0, 0, 0.25);
  }
  .range-picker-calender {
    display: flex;
    border: none;
    gap: 30px;
  }
}

@media screen and (max-width: 1280px) {
  .outside-datepiker-container {
    min-width: 329px;
    max-width: 480px;
    position: fixed;
    top: 41px;
    right: 0;
    padding: 20px;
    border: 1px solid #f2f2f2;
    background-color: white;
    border-radius: 5px;
    overflow: scroll;
    z-index: 20;
  }

  .range-picker-calender {
    display: flex;
    flex-direction: column;
    border: none;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 20;
}

.react-datepicker__month-container {
  width: 100%;
  height: 100%;
}

.react-datepicker__header {
  background-color: #fff;
  border: none;
  width: 100%;
  padding: 0px;
}

.outside-datepiker-container .react-datepicker__current-month {
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 20px;
  color: #172b4d;
  margin-bottom: 18px;
  color: #172b4d;
}

.outside-datepiker-container .react-datepicker__navigation {
  color: #44546f !important;
  position: absolute;
  font-size: 20px;
  top: 0px;
}

.outside-datepiker-container .react-datepicker__navigation--previous {
  left: 0px;
}

.outside-datepiker-container .react-datepicker__navigation--next {
  right: 0px;
}

.outside-datepiker-container .react-datepicker__navigation-icon::before {
  border-color: #44546f;
  border-width: 2px 2px 0 0;
}

/* styling week days */
.outside-datepiker-container .react-datepicker__day-name {
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  width: 36px;
  height: 32px;
  color: #44546f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* styling days */
.outside-datepiker-container .react-datepicker__month {
  margin: 0;
  margin-top: 8px;
}

.outside-datepiker-container .react-datepicker__day {
  width: 36px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #44546f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.outside-datepiker-container .react-datepicker__day--outside-month,
.react-datepicker__day--disabled {
  color: rgba(9, 30, 66, 0.31);
}

.outside-datepiker-container .react-datepicker__day--selected {
  background-color: #fff9ea !important;
  color: #fcc500 !important;
}

.outside-datepiker-container .react-datepicker__day--selected:hover {
  background-color: #fff9ea !important;
}

.outside-datepiker-container .react-datepicker__day--today {
  color: #fcb500;
  font-weight: 700;
  position: relative;
  background-color: #fff;
}

.outside-datepiker-container .react-datepicker__day--today:hover {
  background-color: #fff9ea;
}

.outside-datepiker-container .react-datepicker__day--today::after {
  content: "";
  height: 2px;
  width: 33px;
  background-color: #fcb500;
  position: absolute;
  bottom: 4px;
}

.outside-datepiker-container .react-datepicker__day--keyboard-selected {
  background-color: #fff9ea !important;
  color: #fcc500;
}

.outside-datepiker-container .react-datepicker__day--in-selecting-range {
  background-color: #fff9ea !important;
}

.outside-datepiker-container .react-datepicker__day--in-range {
  background-color: #fff9ea;
}
.outside-datepiker-container .react-datepicker__day--range-start {
  background-color: #ffe99e !important;
  color: #e49b00 !important;
}

.outside-datepiker-container .react-datepicker__day--range-start:hover {
  background-color: #ffe99e !important;
  color: #e49b00 !important;
}

.outside-datepiker-container .react-datepicker__day--range-end {
  background-color: #ffe99e !important;
  color: #e49b00 !important;
}

.outside-datepiker-container .react-datepicker__day--range-end:hover {
  background-color: #ffe99e !important;
  color: #e49b00 !important;
}

.outside-datepiker-container .react-datepicker__day--outside-month {
  visibility: hidden !important;
  display: none;
}

.react-datepicker__month .react-datepicker__week:first-child {
  margin-top: 0;
  display: flex;
  justify-content: flex-end !important;
  width: 100%;
}

.react-datepicker__month .react-datepicker__week:last-child {
  margin-top: 0;
  display: flex;
  justify-content: flex-start !important;
  width: 100%;
}
